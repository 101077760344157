import { css, StyleSheet } from 'aphrodite/no-important';
import { useTheme } from '../../../theming/useTheme';
import { componentConfigurationKey as articleBlockComponentConfigurationKey } from '../../articleComponents/ArticleBlock.theme';
const calculateImageUrl = (host, path, manipulation) => `${host + manipulation}/${path}`;
const isHighDprInLargeImage = (width, dpr) => width >= 600 && dpr > 2;
const addCroppingParamsToPath = (path, croppingData) => {
    if (croppingData) {
        return `c_crop,w_${croppingData.width},h_${croppingData.height},x_${croppingData.x},y_${croppingData.y}/${path}`;
    }
    return path;
};
export const createImageSrc = (host, path, width, aspectRatio, dpr, croppingData) => {
    if (isHighDprInLargeImage(width, dpr)) {
        return createImageSrc(host, path, width, aspectRatio, 2, croppingData);
    }
    return calculateImageUrl(host, path, addCroppingParamsToPath(`c_fill,w_${width * dpr},ar_${aspectRatio.x}:${aspectRatio.y},f_auto,q_auto,g_auto`, croppingData));
};
export const getImageSrcAndSrcSet = (imageAspectRatios, imageWidths, host, path, cropping) => {
    const encodedPathForSrcset = host && host.includes('minutemediacdn') ? encodeURIComponent(path) : path;
    return {
        srcSetSmallScreen: `${createImageSrc(host, encodedPathForSrcset, imageWidths.small, imageAspectRatios.small, 1, cropping)} 1x, ${createImageSrc(host, encodedPathForSrcset, imageWidths.small, imageAspectRatios.small, 2, cropping)} 2x, ${createImageSrc(host, encodedPathForSrcset, imageWidths.small, imageAspectRatios.small, 3, cropping)} 3x`,
        srcSetMediumScreen: `${createImageSrc(host, encodedPathForSrcset, imageWidths.medium, imageAspectRatios.medium, 1, cropping)} 1x, ${createImageSrc(host, encodedPathForSrcset, imageWidths.medium, imageAspectRatios.medium, 2, cropping)} 2x, ${createImageSrc(host, encodedPathForSrcset, imageWidths.medium, imageAspectRatios.medium, 3, cropping)} 3x`,
        srcSetLargeScreen: `${createImageSrc(host, encodedPathForSrcset, imageWidths.large, imageAspectRatios.large, 1, cropping)} 1x, ${createImageSrc(host, encodedPathForSrcset, imageWidths.large, imageAspectRatios.large, 2, cropping)} 2x, ${createImageSrc(host, encodedPathForSrcset, imageWidths.large, imageAspectRatios.large, 3, cropping)} 3x`,
        lowResUrl: createImageSrc(host, path, 16, { x: 16, y: 9 }, 1, cropping),
        fallbackSrc: createImageSrc(host, path, imageWidths.medium, imageAspectRatios.medium, 1, cropping),
    };
};
export const imageStyle = StyleSheet.create({
    base: {
        position: 'absolute',
        top: '0',
        left: '0',
        bottom: '0',
        minWidth: '100%',
        maxWidth: '100%',
        maxHeight: '100%',
        transition: 'opacity 0.2s ease-in-out',
        objectFit: 'cover',
        minHeight: '100%',
    },
});
export const getImageCaption = (credit, caption) => {
    return [caption, credit].filter(value => {
        return typeof value === 'string' && value.trim() !== '';
    }).join(' | ');
};
export const createImageWidths = (small, medium, large) => ({
    small,
    medium,
    large,
});
export const createImageHeightCalculationMethods = (small, medium, large) => ({
    small,
    medium,
    large,
});
export const createImageAspectRatios = (small, medium, large) => ({
    small,
    medium,
    large,
});
const getCreditStyle = (linkColor) => StyleSheet.create({
    style: {
        color: linkColor,
        textDecoration: 'none',
    },
}).style;
export const useTransformCreditHTML = (credit) => {
    const { creditLinkColor } = useTheme(articleBlockComponentConfigurationKey);
    const styleForCredit = css(getCreditStyle(creditLinkColor));
    if (!credit) {
        return '';
    }
    return credit
        .replace(/<a/g, `<a class="${styleForCredit}"`)
        .replace(/div/g, 'span');
};
